import {
    PROFILE_PERSONAL_DATA_ENDPOINT,
    PROFILE_DEPENDANTS_ENDPOINT,
    PROFILE_USERS_ENDPOINT,
    PROFILE_MODERATORS_ENDPOINT,
    PROFILE_CUSTOMERS_ENDPOINT,
    PROFILE_COMPANIES_ENDPOINT,
    PROFILE_BANK_ACCOUNTS_ENDPOINT,
    PROFILE_COMPANY_ENDPOINT, PROFILE_USERS_PAGES_ENDPOINT, COMPANY_ENDPOINT, PROFILE_ENDPOINT
} from '@/constants/endpoints'
import UserPersonalDataDTO from "@/dto/UserPersonalDataDTO";
import axios from "axios";
import authHeader from "@/services/auth-header";
import DependantsDTO from "@/dto/DependantsDTO";
import UserDataDTO from "@/dto/UserDataDTO";
import CompaniesDTO, {CompanyDTO} from "@/dto/CompaniesDTO";
import CustomUserFileDTO from "@/dto/files/CustomUserFileDTO";
import RequestUsersFilterDTO from "@/dto/auth/RequestUsersFilterDTO";
import CompanyFilterDTO from "@/dto/company/CompanyFilterDTO";
import QueryUtils from "@/utils/QueryUtils";
import Pageable from "@/dto/Pageable";
import ProfileFilterDTO from "@/dto/profile/ProfileFilterDTO";
import ProfileDTO from "@/dto/profile/ProfileDTO";

class ProfileService {

    saveUserPersonalData(personalData: UserPersonalDataDTO) {
        return axios.post<UserPersonalDataDTO>(`${PROFILE_PERSONAL_DATA_ENDPOINT}`, personalData, {headers: authHeader()});
    }

    loadPersonalData(userId: number) {
        return axios.get<UserPersonalDataDTO>(`${PROFILE_PERSONAL_DATA_ENDPOINT}/${userId}`, {headers: authHeader()});
    }

    loadDependants(userId: number) {
        return axios.get<DependantsDTO>(`${PROFILE_DEPENDANTS_ENDPOINT}/${userId}`, {headers: authHeader()});
    }

    saveDependants(dependants: DependantsDTO) {
        return axios.post<DependantsDTO>(`${PROFILE_DEPENDANTS_ENDPOINT}`, dependants, {headers: authHeader()});
    }

    loadUsers(filter: RequestUsersFilterDTO) {
        return axios.post<Array<UserDataDTO>>(`${PROFILE_USERS_ENDPOINT}`, filter,{headers: authHeader()});
    }

    loadUsersPagesCount(filter: RequestUsersFilterDTO) {
        return axios.post<number>(`${PROFILE_USERS_PAGES_ENDPOINT}`, filter,{headers: authHeader()});
    }

    loadModerators(){
        return axios.get<Array<UserDataDTO>>(PROFILE_MODERATORS_ENDPOINT, {headers: authHeader()});
    }

    loadCustomers(){
        return axios.get<Array<UserDataDTO>>(PROFILE_CUSTOMERS_ENDPOINT, {headers: authHeader()});
    }

    loadCompanies(userId: number){
        return axios.get<CompaniesDTO>(PROFILE_COMPANIES_ENDPOINT, {
            params: {userId: userId},
            headers: authHeader()
        });
    }

    saveCompanies(companies: CompaniesDTO){
        return axios.post<CompaniesDTO>(PROFILE_COMPANIES_ENDPOINT, companies, {headers: authHeader()});
    }

    loadCompany(companyId: number){
        return axios.get<CompanyDTO>(`${PROFILE_COMPANY_ENDPOINT}/${companyId}`, {headers: authHeader()});
    }

    saveCompany(company: CompanyDTO) {
        return axios.post<CompanyDTO>(PROFILE_COMPANY_ENDPOINT, company,{headers: authHeader()});
    }

    getByFilter(filter: ProfileFilterDTO) {
        const queryString = QueryUtils.buildQueryString(
            ['query', filter.searchQuery]
        );
        return axios.get<Array<ProfileDTO>>(`${PROFILE_ENDPOINT}/search${queryString}`, {headers: authHeader()});
    }

}

export default new ProfileService();